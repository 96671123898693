<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="pa-4 text-left">
        <v-card-title class="pa-0">New Notification</v-card-title>
        <v-divider></v-divider>
        <div class="py-4">
          <div>
            <v-autocomplete
              outlined
              dense
              :loading="loadUsers"
              item-value="firebaseToken"
              item-text="fullName"
              :items="users"
              :search-input.sync="search"
              @update:search-input="searchUsers"
              v-model="selected"
              return-object
              placeholder="Select User (sends to all users when none is selected)"
            >
            </v-autocomplete>
          </div>
          <div>
            <v-text-field
              outlined
              dense
              v-model="form.title"
              placeholder="Notification title"
            ></v-text-field>
          </div>
          <div>
            <v-textarea
              outlined
              rows="5"
              placeholder="Notification message"
              v-model="form.body"
            ></v-textarea>
          </div>
          <div>
            <v-text-field
              outlined
              dense
              v-model="form.route"
              placeholder="Notification route - e.g /transactions-details/{reference}"
            ></v-text-field>
          </div>
          <div class="mt-n4">
            <v-checkbox v-model="form.sendNow" label="Send Now"></v-checkbox>
          </div>
          <div v-if="!form.sendNow">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Schedule notification time"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :min="new Date().toISOString().substr(0, 10)"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </div>
        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn depressed class="close mr-3" @click="toggleDialog(false)">
            Close
          </v-btn>
          <v-btn
            depressed
            class="close primary"
            @click="createNotification"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Helpers from "@/utils/helpers";
import { mapActions } from "vuex";
import ResponseMessage from "@/components/ResponseMessage.vue";
const initialData = () => {
  return {
    dialog: false,
    helpers: new Helpers(),
    form: {
      sendNow: true,
      topic: "news",
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    users: [],
    selected: {},
    loadUsers: false,
    search: "",
    searchTimerId: null,
    loading: false,
  };
};
export default {
  name: "NewNotification",
  comments: {
    ResponseMessage,
  },
  data() {
    return initialData();
  },
  computed: {},
  methods: {
    ...mapActions({
      get_users: "users/get_users",
      submit_notification: "notifications/submit_notification",
    }),
    toggleDialog(value) {
      this.dialog = value;
      if (!value) {
        this.search = "";
        this.getUsers();
      }
    },
    async getUsers() {
      this.loadUsers = true;
      let query = `?page=1&limit=10`;
      if (this.search !== "" && this.search != null) {
        query += `&search=${this.search}`;
      }
      try {
        const response = await this.get_users(query);
        this.loadUsers = false;
        this.users = response.data.users.filter((x) => x.firebaseToken != null);
        if (this.searchTimerId) {
          clearTimeout(this.searchTimerId);
        }
      } catch (e) {
        this.loadUsers = false;
        console.log(e);
      }
    },
    searchUsers() {
      if (typeof this.selected === "object") {
        this.form.firebaseToken = this.selected?.firebaseToken;
      }
      if (this.search === this.selected?.fullName) return;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getUsers();
      }, 1000);
    },
    async createNotification() {
      this.loading = true;
      try {
        const response = await this.submit_notification(this.form);
        this.loading = false;
        this.toggleDialog(false);
        this.loading = false;
        this.$parent.responseType = "success";
        this.$parent.responseMessage = response.message;
        this.$parent.showResponse = true;
        Object.assign(this.$data, initialData());
      } catch (e) {
        this.toggleDialog(false);
        this.loading = false;
        this.$parent.responseType = "error";
        this.$parent.responseMessage = e?.response?.data?.message;
        this.$parent.showResponse = true;
      }
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style scoped>
.v-dialog__content {
  justify-content: flex-end;
}
</style>
